<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide class="swiper-slide">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl tbl_sum1">
              <thead>
                <tr>
                  <th>장비</th>
                  <th>품목</th>
                  <th>금일 목표</th>
                  <th>현재 실적</th>
                  <th>달성율(%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in machineLogs[0]" :key="item.id">
                  <td>
                    {{ findInfoFromId(machines, item.plc_sensor_id).name }}
                  </td>
                  <td>
                    {{ findInfoFromId(products, item.product_id).name }}
                  </td>
                  <td>
                    {{ $makeComma(item.quantity) }}
                  </td>
                  <td>{{ $makeComma(item.value) }}</td>
                  <td>{{ item.achivement }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide chart-wrap">
          <div v-for="chart in machineLogs[0]" :key="chart.id">
            <span
              >{{
                $decimalMul(
                  $decimalDiv(chart.value, chart.total_value),
                  100,
                ).toFixed(0)
              }}%</span
            >
            <doughnut-chart
              :data="chart.data"
              :options="chartData.options"
              :key="keys"
            />
            <p>
              {{ findInfoFromId(machines, chart.plc_sensor_id).name }}
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl tbl_sum2">
              <thead>
                <tr>
                  <th>장비</th>
                  <th>품목</th>
                  <th>금일 목표</th>
                  <th>현재 실적</th>
                  <th>달성율(%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in machineLogs[1]" :key="item.id">
                  <td>
                    {{ findInfoFromId(machines, item.plc_sensor_id).name }}
                  </td>
                  <td>{{ findInfoFromId(products, item.product_id).name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.achivement }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide chart-wrap">
          <div v-for="chart in machineLogs[1]" :key="chart.id">
            <span
              >{{
                $decimalMul(
                  $decimalDiv(chart.value, chart.total_value),
                  100,
                ).toFixed(0)
              }}%</span
            >
            <doughnut-chart
              :data="chart.data"
              :options="chartData.options"
              :key="keys"
            />
            <p>
              {{ findInfoFromId(machines, chart.plc_sensor_id).name }}
            </p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FETCH_MIXIN from '@/mixins/fetch';
import DoughnutChart from '@/layouts/components/charts/DoughnutChart';
import { yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';
export default {
  mixins: [SWIPER_MIXIN, FETCH_MIXIN],
  components: {
    DoughnutChart,
  },
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      keys: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      plc_counter_log_day: 'getCounterLogDay',
      target_quantity_now: 'getTargetQuantity',
      products: 'getProduct',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
      machines: 'getMachines',
    }),
    machineLogs() {
      if (
        this.plc_counter_log_day != undefined &&
        this.plc_counter_log_day.length > 0 &&
        this.target_quantity_now != undefined &&
        this.target_quantity_now.length > 0
      ) {
        let targetClone = this.lodash.clonedeep(this.target_quantity_now);
        let clone = this.lodash
          .clonedeep(this.plc_counter_log_day)
          .filter(
            x =>
              x.plc_sensor_detail_type_id == 4 &&
              targetClone.find(y => y.product_id == x.product_id),
          )
          .sort(
            (a, b) =>
              a.product_id - b.product_id || a.plc_sensor_id - b.plc_sensor_id,
          );

        console.log('타겟제품', clone);

        let filterTarget = clone.map(x => {
          let quantity = targetClone.find(x =>
            clone.find(y => y.product_id == x.product_id),
          ).quantity;
          let total_value = clone.map(x => x.value).reduce((a, b) => a + b, 0);
          x.quantity = quantity;
          x.total_value = total_value;
          x.achivement = this.$decimalMul(
            this.$decimalDiv(total_value, quantity),
            100,
          ).toFixed(0);

          x.data = {
            datasets: [
              {
                data: [
                  this.$decimalMul(
                    this.$decimalDiv(x.value, total_value),
                    100,
                  ).toFixed(0),
                  100 -
                    this.$decimalMul(
                      this.$decimalDiv(x.value, total_value),
                      100,
                    ).toFixed(0),
                ],
                backgroundColor: ['#ff2626', '#ffd7d1'],
                fill: false,
                pointRadius: 4,
                lineTension: 0,
              },
            ],
          };
          return x;
        });
        filterTarget = [
          filterTarget.filter((x, index) => index <= 7),
          filterTarget.filter((x, index) => index > 7),
        ];
        return filterTarget;
      } else {
        return [];
      }
    },
    chartData() {
      let options = {
        legend: {
          display: false,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        cutoutPercentage: 70,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };

      return {
        options: options,
      };
    },
  },
  methods: {
    tableSum() {
      let table = document.querySelectorAll('.mes_tbl');
      table.forEach((el, i) => {
        let trArr = el.getElementsByTagName('tr');
        for (let tdIdx = 4; tdIdx >= 1; tdIdx--) {
          if (tdIdx != 3) {
            let rowSpan = 0;
            let compText = '';
            for (let trIdx = 1; trIdx < trArr.length; trIdx++) {
              let td = el.rows[trIdx].cells[tdIdx];
              if (compText == '') {
                compText = td.outerText;
                continue;
              }
              if (compText == td.outerText) {
                rowSpan++;
                td.setAttribute('class', 'del');
              } else {
                let td = el.rows[trIdx - 1 - rowSpan].cells[tdIdx];
                td.setAttribute('rowspan', rowSpan + 1);
                rowSpan = 0;
                compText = el.rows[trIdx].cells[tdIdx].outerText;
              }
              if (trIdx == trArr.length - 1 && rowSpan > 1) {
                let cell = el.rows[trIdx - rowSpan].cells[tdIdx];
                cell.setAttribute('rowspan', rowSpan + 1);
                cell.style.borderBottom = 'none';
                rowSpan = 0;
                compText = '';
              }
            }
          }
        }
        table = document.querySelectorAll('.mes_tbl');
        let dels = table[i].getElementsByClassName('del');
        for (let i = dels.length - 1; i >= 0; i--) {
          dels[i].remove();
        }
      });
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '제품');
    }
    if (this.machines.length == 0) {
      await this.FETCH('FETCH_MACHINE', '설비기기');
    }
    if (this.plc_counter_log_day.length < 1) {
      await this.FETCH('FETCH_COUNTER_LOG_DAY_NOW', '카운터');
    }
    if (this.tabIndex == 1) {
      await this.FETCH('FETCH_TARGET_QUANTITY_NOW', '목표달성');
      this.tableSum();
    }
    this.keys++;
  },
};
</script>

<style></style>
