<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide class=" swiper-slide">
          <div
            class="mes_tbl_wrap"
            v-for="(items, index) in machineLogs"
            :key="index"
          >
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th>생산 설비</th>
                  <th>생산 수량</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>
                    {{
                      item.plc_sensor_id >= 27
                        ? findInfoFromId(machines, item.plc_sensor_id - 13).name
                        : findInfoFromId(machines, item.plc_sensor_id).name
                    }}
                  </td>
                  <td>{{ $makeComma(item.value) }} EA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide chart-slide">
          <BarChart
            :width="$screen.width > 1280 ? $screen.width - 400 : 920"
            :height="$screen.width > 1280 ? 440 : 320"
            :data="chartData.data"
            :options="chartData.options"
            :key="key"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BarChart from '@/layouts/components/charts/TaegwangCustomBarChart';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';
export default {
  mixins: [SWIPER_MIXIN, FETCH_MIXIN],
  components: { BarChart },
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_log_time: 'getPlcSensorLogTimes',
      machines: 'getMachines',
    }),
    machineLogs() {
      if (
        this.plc_sensor_logs != undefined &&
        this.plc_sensor_logs.length > 0
      ) {
        let clone = this.lodash
          .clonedeep(this.plc_sensor_logs)
          .filter(
            x =>
              (x.plc_sensor_id >= 1 && x.plc_sensor_id < 14) ||
              (x.plc_sensor_id >= 27 && x.plc_sensor_id < 30),
          )
          .sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);

        let arr = [];
        clone.forEach((el, index) => {
          if (index % 8 == 0) {
            arr.push(clone.slice(index, index + 8));
          }
        });
        return arr;
      } else {
        return [];
      }
    },
    chartData() {
      if (this.plc_sensor_log_time.length < 1) {
        return [];
      } else {
        const machine_product = this.lodash.clonedeep(this.plc_sensor_log_time);

        let filter_input_day = machine_product
          .map(x => ({
            ...x,
            input_day: x.input_time.substring(0, 10),
          }))
          .sort((a, b) => new Date(a.input_day) - new Date(b.input_day));
        let findIndex = filter_input_day.findIndex(
          (x, index) => x.input_day !== filter_input_day[index + 1].input_day,
        );
        let yesterday = filter_input_day.slice(0, findIndex + 1);
        let today = filter_input_day.slice(
          findIndex + 1,
          filter_input_day.length - 1,
        );

        let filter_day = [yesterday, today];

        const obj = filter_day[0].reduce(function(rv, x) {
          (rv[x['input_time']] = rv[x['input_time']] || []).push(x);
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          let product_sum = obj[k].map(x => x.value).reduce((a, b) => a + b, 0);
          arr.push({
            product_sum,
            time: Number(obj[k][0].input_time.substring(11, 13)),
          });
        }

        const obj2 = filter_day[1].reduce(function(rv, x) {
          (rv[x['input_time']] = rv[x['input_time']] || []).push(x);
          return rv;
        }, {});

        let arr2 = [];
        for (let k in obj2) {
          let product_sum = obj2[k]
            .map(x => x.value)
            .reduce((a, b) => a + b, 0);
          arr2.push({
            product_sum,
            time: Number(obj2[k][0].input_time.substring(11, 13)),
          });
        }

        let productSum = [
          arr
            .filter(x => x.time >= 8 && x.time < 20)
            .sort((a, b) => a.time - b.time),
          arr2
            .filter(x => x.time >= 8 && x.time < 20)
            .sort((a, b) => a.time - b.time),
        ];
        const data = {
          labels: [
            '8시',
            '9시',
            '10시',
            '11시',
            '12시',
            '13시',
            '14시',
            '15시',
            '16시',
            '17시',
            '18시',
            '19시',
          ],
          datasets: [
            {
              label: '어제',
              data: productSum[0].map(x => x.product_sum),
              borderColor: '#cecece',
              backgroundColor: '#cecece',
              borderWidth: 0,
              barPercentage: 0.5,
            },
            {
              label: '오늘',
              data: productSum[1].map(x => x.product_sum),
              borderColor: '#ff2626',
              backgroundColor: '#ff2626',
              borderWidth: 0,
              barPercentage: 0.5,
            },
          ],
        };
        let options = {
          legend: {
            display: true,
            responsive: true,
            position: 'right',
            align: 'start',
            labels: {
              boxWidth: 10,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          scales: {
            yAxes: [
              {
                stacked: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 78,
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          showTooltips: true,
          tooltips: {
            mode: 'index',
            intersect: false,
            custom: function(tooltip) {
              if (!tooltip) return;
              tooltip.displayColors = false;
            },
            // enabled: false,
            callbacks: {
              label: tooltipItems => {
                if (tooltipItems.datasetIndex === 0) {
                  let yesterTimeFilter = productSum[0]
                    // .filter(x => x.product_sum == tooltipItems.value)
                    .filter(
                      x => x.time == Number(tooltipItems.label.split('시')[0]),
                    )
                    .map(x => x.product_sum);
                  let todayTimeFilter = productSum[1]
                    .filter(
                      x => x.time == Number(tooltipItems.label.split('시')[0]),
                    )
                    .map(x => x.product_sum);
                  let multistringText = [];
                  console.log(tooltipItems);
                  multistringText.push(
                    `[어제] : ${yesterTimeFilter}`,
                    `[오늘] : ${todayTimeFilter}`,
                  );
                  return multistringText;
                }
              },
            },
          },
        };
        return { data: data, options: options };
      }
    },
  },
  async created() {
    if (this.plc_sensor_log_time.length == 0) {
      await this.FETCH('FETCH_PLC_SENSOR_LOG_TIMES', '설비별 생산차트');
      this.key++;
    }
    await this.FETCH('FETCH_PLC_SENSOR_LOG_NOW', '설비별 로그');
    if (this.machines.length == 0) {
      await this.FETCH('FETCH_MACHINE', '설비기기');
    }
  },
};
</script>

<style></style>
