var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status_board_taegwang",class:{
    plc_taegwang: _vm.tabIndex == 0,
    target_status: _vm.tabIndex == 1,
    facility_production_quantity: _vm.tabIndex == 2,
    machine_abnormal_status: _vm.tabIndex == 3,
    emergency_work_order: _vm.tabIndex == 4,
    notice: _vm.tabIndex == 5,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToStatusMonitorPage',"options":_vm.asideIndex}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',_vm._l((_vm.asideIndex),function(aside,index){return _c('li',{key:index,class:{ active: _vm.tabIndex == index }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(index)}}},[_vm._v(_vm._s(aside.title))])])}),0)]):_vm._e(),(_vm.tabIndex == 0)?_c('ProductionStatusForm'):_vm._e(),(_vm.tabIndex == 1)?_c('TargetStatusForm'):_vm._e(),(_vm.tabIndex == 2)?_c('FacilityProductionQuantityForm'):_vm._e(),(_vm.tabIndex == 3)?_c('MachineAbnormalStatusForm'):_vm._e(),(_vm.tabIndex == 4)?_c('EmergencyWorkOrderForm'):_vm._e(),(_vm.tabIndex == 5)?_c('OneNoticeForm'):_vm._e(),(_vm.emergencyOpen)?_c('EmergencyModal',{on:{"onclose":function($event){_vm.emergencyOpen = false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }