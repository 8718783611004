<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>생산 설비</th>
              <th>이상횟수</th>
              <th>지연시간</th>
            </tr>
          </thead>
          <tbody>
            <!-- v-for="(item, index) in errorLogs.length > 0 ? errorLogs : 3"
              :key="item.id" -->
            <tr>
              <td>
                {{
                  errorLogs.length > 0
                    ? findInfoFromId(machines, errorLogs[0].plc_sensor_id - 19)
                        .name
                    : `14호 크립자동조립기`
                }}
              </td>
              <td>
                {{ errorLogs.length > 0 ? `${errorLogs[0].count}회` : '0회' }}
              </td>
              <td>
                {{ errorLogs.length > 0 ? `${errorLogs[0].hour}시` : `0시` }}
                {{ errorLogs.length > 0 ? `${errorLogs[0].min}분` : `0분` }}
                {{ errorLogs.length > 0 ? `${errorLogs[0].sec}초` : `0초` }}
              </td>
            </tr>
            <tr>
              <td>
                {{
                  errorLogs.length > 1
                    ? findInfoFromId(machines, errorLogs[1].plc_sensor_id - 19)
                        .name
                    : `15호 크립자동조립기`
                }}
              </td>
              <td>
                {{ errorLogs.length > 1 ? `${errorLogs[1].count}회` : '0회' }}
              </td>
              <td>
                {{ errorLogs.length > 1 ? `${errorLogs[1].hour}시` : `0시` }}
                {{ errorLogs.length > 1 ? `${errorLogs[1].min}분` : `0분` }}
                {{ errorLogs.length > 1 ? `${errorLogs[1].sec}초` : `0초` }}
              </td>
            </tr>
            <tr>
              <td>
                {{
                  errorLogs.length > 2
                    ? findInfoFromId(machines, errorLogs[2].plc_sensor_id - 19)
                        .name
                    : `16호 크립자동조립기`
                }}
              </td>
              <td>
                {{ errorLogs.length > 2 ? `${errorLogs[2].count}회` : '0회' }}
              </td>
              <td>
                {{ errorLogs.length > 2 ? `${errorLogs[2].hour}시` : `0시` }}
                {{ errorLogs.length > 2 ? `${errorLogs[2].min}분` : `0분` }}
                {{ errorLogs.length > 2 ? `${errorLogs[2].sec}초` : `0초` }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="graph">
        <BarChart
          :width="$screen.width > 1280 ? 700 : 480"
          :height="$screen.width > 1280 ? 400 : 280"
          :data="chartData.data"
          :options="chartData.options"
          :key="key"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';
import BarChart from '@/layouts/components/charts/TaegwangCustomBarChart';
export default {
  mixins: [FETCH_MIXIN],
  components: { BarChart },
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_error_log: 'getPlcErrorLogs',
      plc_sensor: 'getPlcSensor',
      machines: 'getMachines',
    }),
    errorLogs() {
      if (this.plc_error_log != undefined && this.plc_error_log.length > 0) {
        const error_log = this.lodash.clonedeep(this.plc_error_log);

        let clone = this.lodash
          .clonedeep(error_log)
          .filter(x => x.plc_sensor_id != null)
          .filter(
            x =>
              yyyy년MM월dd일(new Date(x.create_time)) ===
              yyyy년MM월dd일(new Date()),
          )
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
        const obj = clone.reduce(function(rv, x) {
          (rv[x['plc_sensor_id']] = rv[x['plc_sensor_id']] || []).push(x);
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          let seconds = obj[k].map(x => x.value);
          let hour = parseInt(this.$decimalDiv(seconds[0], 3600));
          let min = parseInt(
            this.$decimalDiv(this.$decimalMod(seconds[0], 3600), 60),
          );
          let sec = this.$decimalMod(seconds[0], 60);

          arr.push({
            plc_sensor_id: k,
            hour,
            min,
            sec,
            count: obj[k][0].error_count,
          });
        }
        return arr;
      } else {
        return [];
      }
    },
    yesterdayLogs() {
      if (this.plc_error_log != undefined && this.plc_error_log.length > 0) {
        const error_log = this.lodash.clonedeep(this.plc_error_log);

        let clone = this.lodash
          .clonedeep(error_log)
          .filter(x => x.plc_sensor_id != null)
          .filter(
            x =>
              yyyy년MM월dd일(new Date(x.create_time)) !==
              yyyy년MM월dd일(new Date()),
          )
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
        const obj = clone.reduce(function(rv, x) {
          (rv[x['plc_sensor_id']] = rv[x['plc_sensor_id']] || []).push(x);
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          let seconds = obj[k].map(x => x.value);
          let min = parseInt(
            this.$decimalDiv(this.$decimalMod(seconds[0], 3600), 60),
          );
          arr.push({
            plc_sensor_id: k,
            min,
            count: obj[k][0].error_count,
          });
        }
        return arr;
      } else {
        return [];
      }
    },
    chartData() {
      let yesterday = this.lodash.clonedeep(this.yesterdayLogs);
      let today = this.lodash.clonedeep(this.errorLogs);
      const data = {
        labels: [
          '14호 크립자동조립기',
          '15호 크립자동조립기',
          '16호 크립자동조립기',
        ],
        datasets: [
          {
            label: `어제`,
            data: yesterday.map(x => x.min),
            count: yesterday.map(x => x.count),
            borderColor: '#cecece',
            backgroundColor: '#cecece',
            borderWidth: 0,
            barPercentage: 0.9,
          },
          {
            label: `오늘`,
            data: today.map(x => (x.min ? x.min : x)),
            count: today.map(x => (x.count ? x.count : x)),
            borderColor: '#ff2626',
            backgroundColor: '#ff2626',
            borderWidth: 0,
            barPercentage: 0.9,
          },
        ],
      };
      let options = {
        parsing: {
          xAxisKey: 'id',
          yAxisKey: 'nested.value',
        },
        legend: {
          display: true,
          responsive: true,
          position: 'right',
          align: 'start',
          labels: {
            boxWidth: 10,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };
      return { data: data, options: options };
      // }
    },
  },
  async created() {
    await this.FETCH('FETCH_PLC_ERROR_LOG_DAYS', '조립기 이상 현황');
    await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    if (this.machines.length == 0) {
      await this.FETCH('FETCH_MACHINE', '설비기기');
    }
    this.key++;
  },
};
</script>

<style></style>
