<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_option">
      <h2 class="curr_time">{{ curr_date }} {{ curr_weekDay }}</h2>
      <button @click="fullScreen = !fullScreen" class="btn_sub1">
        {{ fullScreen ? '닫기' : '전체화면' }}
      </button>
    </div>

    <div v-if="lots.length > 0" class="card">
      <h3>
        [긴급]
        <p>작업지시</p>
      </h3>
      <ul>
        <li>
          <span>품명 : </span
          >{{ findInfoFromId(products, lots.product_id).name }}
        </li>
        <li>
          <span>수량 : </span>{{ `${$makeComma(lots.quantity)}` }}
          {{
            `${
              findInfoFromId(
                units,
                findInfoFromId(products, lots.product_id).stock_unit_id,
              ).name
            }`
          }}
        </li>
        <li><span>담당 : </span>사출팀</li>
        <li><span>납기 : </span>{{ yyyy년MM월dd일(lots.deadline_date) }}</li>
      </ul>
    </div>
    <div v-else class="card none_order">
      <h4>
        긴급작업지시 없음
      </h4>
    </div>
  </div>
</template>

<script>
import { yyyy년MM월dd일, X요일 } from '@/utils/func';
import { mapGetters } from 'vuex';
import FetchMixin from '@/mixins/fetch';
import { formatDateNoHours } from '@/utils/filters';
import SpinnerMixin from '@/mixins/spinner';

export default {
  mixins: [FetchMixin, SpinnerMixin],
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      lotsSrc: 'getLotFromWorkOrder',
      units: 'getUnitCodes',
    }),
    lots() {
      let lot = this.lodash
        .clonedeep(this.lotsSrc)
        .filter(x => x.emergency_yn == true && x.lot_type_id == 1);

      if (this.lotsSrc.length < 1) {
        return [];
      } else {
        return lot;
      }
    },
  },
  methods: {
    yyyy년MM월dd일(date2) {
      const date = new Date(date2);
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [
        date.getFullYear(),
        '년 ' + (MM > 9 ? MM : '0' + MM),
        '월 ' + (dd > 9 ? dd : '0' + dd),
        '일 ',
      ].join('');
    },
    async FETCH_LOT_DAY() {
      this.showSpinner();
      let start_date = new Date();
      let end_date = new Date(start_date);
      end_date.setDate(end_date.getDate() + 1);
      this.$store
        .dispatch('FETCH_LOT_DAY_FROM_WORKORDER', {
          start: formatDateNoHours(start_date),
          end: formatDateNoHours(end_date),
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '작업지시서를 불러오는 중 오류발생.',
          );
        });
      this.hideSpinner();
    },
  },
  async created() {
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH_LOT_DAY();

    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
  },
};
</script>

<style></style>
