<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_option">
      <h2 class="curr_time">{{ curr_date }} {{ curr_weekDay }}</h2>
      <button @click="fullScreen = !fullScreen" class="btn_sub1">
        {{ fullScreen ? '닫기' : '전체화면' }}
      </button>
    </div>
    <div
      v-if="notices.length > 0 && cur_date == notices[0].expired_day"
      class="card"
    >
      <h3>공지사항</h3>
      <p>
        <span>
          {{ notices[0].title }}
        </span>
      </p>
      <span>
        {{ notices[0].detail }}
      </span>
    </div>
    <div v-else class="card">
      <h3>
        공지사항 없음
      </h3>
    </div>
  </div>
</template>

<script>
import { yyyy년MM월dd일, X요일 } from '@/utils/func';
import { mapGetters } from 'vuex';
import FetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [FetchMixin, SpinnerMixin],
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      cur_date: yyyymmdd(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      notices: 'getNoticeList',
      managementData: 'getManagementDataFromNoticePage',
    }),
  },
  methods: {
    async fetch_notice_list() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_NOTICE_LIST')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '공지사항 리스트를 불러오는 중 오류발생.',
          );
        });
      this.hideSpinner();
    },
  },
  async created() {
    await this.fetch_notice_list();
  },
};
</script>

<style></style>
