<template>
  <!-- production_status: tabIndex == 0, -->
  <div
    id="contents"
    class="status_board_taegwang"
    :class="{
      plc_taegwang: tabIndex == 0,
      target_status: tabIndex == 1,
      facility_production_quantity: tabIndex == 2,
      machine_abnormal_status: tabIndex == 3,
      emergency_work_order: tabIndex == 4,
      notice: tabIndex == 5,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="asideIndex"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <ProductionStatusForm v-if="tabIndex == 0" />
      <TargetStatusForm v-if="tabIndex == 1" />
      <FacilityProductionQuantityForm v-if="tabIndex == 2" />
      <MachineAbnormalStatusForm v-if="tabIndex == 3" />
      <EmergencyWorkOrderForm v-if="tabIndex == 4" />
      <OneNoticeForm v-if="tabIndex == 5" />
      <EmergencyModal v-if="emergencyOpen" @onclose="emergencyOpen = false" />
    </div>
  </div>
</template>

<script>
import TargetStatusForm from '@/views/forms/Custom/Monitor/19/TargetStatusForm';
import FacilityProductionQuantityForm from '@/views/forms/Custom/Monitor/19/FacilityProductionQuantityForm';
import MachineAbnormalStatusForm from '@/views/forms/Custom/Monitor/19/MachineAbnormalStatusForm';
import EmergencyWorkOrderForm from '@/views/forms/Custom/Monitor/19/EmergencyWorkOrderForm';
import ProductionStatusForm from '@/views/forms/Custom/Monitor/19/ProductionStatusForm';
import OneNoticeForm from '@/views/forms/Custom/Monitor/19/OneNoticeForm';
import EmergencyModal from '@/layouts/components/EmergencyModal';

import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    TargetStatusForm,
    FacilityProductionQuantityForm,
    ProductionStatusForm,
    MachineAbnormalStatusForm,
    EmergencyWorkOrderForm,
    OneNoticeForm,
    EmergencyModal,
  },
  data() {
    return {
      emergencyOpen: false,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      asideIndex: [
        { title: '생산 현황', name: 'production_status' },
        { title: '목표달성 현황', name: 'target_status' },
        { title: '설비별 생산수량', name: 'facility_production_quantity' },
        { title: '조립기 이상 현황', name: 'machine_abnormal_status' },
        { title: '긴급작업지시서', name: 'emergency_work_order' },
        { title: '공지사항', name: 'notice' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
      lots: 'getLotFromTodayWorkOrder',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
    destroyed() {
      console.log('destroyed');
      clearInterval(this.interval);
      clearInterval(this.timeInterval);
      clearInterval(this.currentTimeInterval);
    },
    async fetch_lot_all() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_LOT_ALL')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '작업지시서를 불러오는 중 오류발생.',
          );
        });
      this.hideSpinner();
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
    await this.FETCH_NO_SPINNER_NO_POP_UP(
      'FETCH_LOT_TODAY_FROM_WORKORDER',
      '긴급작업지시서',
    );

    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      await this.FETCH_NO_SPINNER_NO_POP_UP(
        'FETCH_LOT_TODAY_FROM_WORKORDER',
        '긴급작업지시서',
      );

      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    if (
      this.lots.length > 0 &&
      this.lots[0].emergency_yn &&
      this.lots[0].lot_type_id == 1
    ) {
      if (localStorage.getItem('emergency_id') != this.lots[0].id) {
        this.emergencyOpen = true;
      }
    } else {
      this.emergencyOpen = false;
    }
  },
};
</script>

<style lang="scss">
@import 'taegwang_monitoring';
</style>
